jQuery(document).ready(function () {
    jQuery('.js-load-more-btn').click(function () {
        let button = jQuery(this),
            data = {
                'action': 'loadmore',
                'query': load_more_params.posts,
                'page': load_more_params.current_page
            };

        jQuery.ajax({
            url: load_more_params.ajax_url,
            data: data,
            type: 'POST',
            beforeSend: function () {
                button.find('.js-load-more-btn-icon').show();
            },
            success: function (data) {
                if (data) {
                    button.find('.js-load-more-btn-icon').hide();
                    jQuery('.js-load-more').append(data);
                    load_more_params.current_page++;

                    if (parseInt(load_more_params.current_page) === parseInt(load_more_params.max_page))
                        button.remove();
                } else {
                    button.remove();
                }
            }
        });
    });
});